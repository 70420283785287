import React, { useState } from 'react';
import './App.css';
import { distanceBetweenTwoPoints, refundAmount, checkIfEURouterIsCloseToDepart, edgeCases } from './utils';

function App() {
  const [location, setLocation] = useState({ string: '' });
  const [showResults, setShowResults] = useState(false);
  const [amount, setAmount] = useState([0, 0]);
  const [distance, setDistance] = useState([0, 0]);
  const [searchBarPlaceholder, setSearchPlaceHolder] = useState('Enter your village/city, and country.');

  const handleLocationChange = (event) => {
    setShowResults(false);
    setLocation({ string: event.target.value, address: {} });
  };

  const calculateReimbursements = async () => {
    const resLocation = await fetch(`https://nominatim.openstreetmap.org/search?q=${location.string}&format=json&countrycodes=at,be,bg,cy,cz,de,dk,ee,es,fi,fr,hr,hu,gl,gr,ie,it,lt,lu,lv,mt,nl,pl,pt,ro,se,si,sk,am,ad,by,ba,ge,is,xk,md,mc,me,mk,no,sm,rs,ch,tr,ua,gb,al,sh&limit=10&addressdetails=1`)
      .then(response => response.json());
    const res = resLocation[0];
    if (res === undefined) {
      setLocation({ string: '' });
      setSearchPlaceHolder('Location not Found');
      return;
    }
    setLocation({ string: res.display_name, address: res.address });

    const distanceFlight = distanceBetweenTwoPoints(res.lat, res.lon, 50.83879505, 4.375304132256188);
    const amountFlight = distanceFlight < 400 ? "Impossible" : edgeCases(res.address) ? edgeCases(res.address) : refundAmount(distanceFlight, res.address);

    const resTrain = await fetch(`https://gisco-services.ec.europa.eu/route/v1/car/${res.lon},${res.lat};4.375304132256188,50.83879505?overview=false&alternatives=true&steps=true&hints=;`);
    if (resTrain.status !== 200) {
      setAmount([amountFlight, "Impossible"]);
      setDistance([Math.floor(distanceFlight), 0]);
      setShowResults(true);
      return;
    }
    const resTrainJson = await resTrain.json();
    const loca = resTrainJson.routes[0].legs[0].steps[0].maneuver.location;
    if (!checkIfEURouterIsCloseToDepart(res, { lat: loca[1], lon: loca[0] })) {
      setAmount([amountFlight, "Impossible"]);
      setDistance([Math.floor(distanceFlight), 0]);
      setShowResults(true);
      return;
    }
    const amountTrain = refundAmount(resTrainJson.routes[0].distance / 1000, res.address);
    setAmount([amountFlight, amountTrain]);
    setDistance([Math.floor(distanceFlight), Math.floor(resTrainJson.routes[0].distance / 1000)]);
    setShowResults(true);
  };

  const isLocationEntered = location.string.trim() !== '';

  return (
    <div className="App">
      <h1>Travel Calculator</h1>
      <div className="main-box">
        <div className="block">
          <h3 className='subline'>Where will you be travelling from?</h3>
          <input
            type="text"
            value={location.string}
            onChange={handleLocationChange}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                calculateReimbursements();
              }
            }}
            placeholder={searchBarPlaceholder}
            className="location-input"
          />
          <div className="arrow-container">
            <span className="arrow">↓</span>
          </div>
          <h3 className='subline'>Where will you be travelling to?</h3>
          <input
            type="text"
            placeholder="City/Town, Country"
            className="location-input"
            disabled
          />
        </div>
        <div><svg xmlns="http://www.w3.org/2000/svg" width="124.726" height="38.762" viewBox="0 0 124.726 38.762"><path d="M123.226,38.762a1.5,1.5,0,0,1-1.391-.938,20.481,20.481,0,0,0-2.221-4.083,1.5,1.5,0,1,1,2.456-1.723,23.485,23.485,0,0,1,2.547,4.682,1.5,1.5,0,0,1-1.39,2.062ZM113.47,27.746a1.493,1.493,0,0,1-.726-.188,20.4,20.4,0,0,0-4.294-1.775,1.5,1.5,0,1,1,.822-2.885,23.4,23.4,0,0,1,4.927,2.037,1.5,1.5,0,0,1-.729,2.812ZM98.939,25h-5a1.5,1.5,0,0,1,0-3h5a1.5,1.5,0,1,1,0,3Zm-15,0h-5a1.5,1.5,0,0,1,0-3h5a1.5,1.5,0,0,1,0,3Zm-15,0h-5a1.5,1.5,0,1,1,0-3h5a1.5,1.5,0,0,1,0,3Zm-15,0h-5a1.5,1.5,0,1,1,0-3h5a1.5,1.5,0,0,1,0,3Zm-15,0h-5a1.5,1.5,0,0,1,0-3h5a1.5,1.5,0,0,1,0,3Zm-15,0H23.5a23.657,23.657,0,0,1-4.837-.5,1.5,1.5,0,0,1,.615-2.936A20.65,20.65,0,0,0,23.5,22h.439a1.5,1.5,0,1,1,0,3ZM9.979,20.356a1.494,1.494,0,0,1-.922-.317,23.661,23.661,0,0,1-3.8-3.73,1.5,1.5,0,1,1,2.328-1.892A20.658,20.658,0,0,0,10.9,17.674a1.5,1.5,0,0,1-.924,2.683ZM2.06,7.958A1.5,1.5,0,0,1,.6,6.795,23.622,23.622,0,0,1,0,1.5a1.5,1.5,0,1,1,3,0,20.618,20.618,0,0,0,.523,4.621A1.5,1.5,0,0,1,2.06,7.958Z" fill="#241f21"/></svg></div>
        <div className={isLocationEntered ? 'block visible' : 'block faded'}>
          <h3 className='subline'>Do you want to travel by</h3>
          <div className="travel-mode-container">
            <input
              type="radio"
              id="train"
              name="travelMode"
              value="train"
              className="travel-mode-input"
            />
            <label htmlFor="train" className="travel-mode-label">
              <span>train</span>
            </label>
            <span className="travel-mode-label">or</span>
            <input
              type="radio"
              id="plane"
              name="travelMode"
              value="plane"
              className="travel-mode-input"
            />
            <label htmlFor="plane" className="travel-mode-label">
              <span>plane</span>
            </label>
          </div>
        </div>
        <div className={isLocationEntered ? 'visible' : 'faded'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="84.5" height="25" viewBox="0 0 84.5 25"><path d="M82,24a1.5,1.5,0,0,1-1.5-1.5,9.458,9.458,0,0,0-.637-3.429,1.5,1.5,0,0,1,2.8-1.083A12.446,12.446,0,0,1,83.5,22.5,1.5,1.5,0,0,1,82,24ZM74.175,13.465a1.5,1.5,0,0,1-.433-.064A9.509,9.509,0,0,0,71,13H69.219a1.5,1.5,0,0,1,0-3H71a12.507,12.507,0,0,1,3.606.528,1.5,1.5,0,0,1-.432,2.937ZM59.219,13h-5a1.5,1.5,0,0,1,0-3h5a1.5,1.5,0,0,1,0,3Zm-15,0h-5a1.5,1.5,0,0,1,0-3h5a1.5,1.5,0,0,1,0,3Zm-15,0h-5a1.5,1.5,0,0,1,0-3h5a1.5,1.5,0,1,1,0,3Zm-15,0H11.5a12.58,12.58,0,0,1-2.572-.265A1.5,1.5,0,0,1,9.542,9.8,9.575,9.575,0,0,0,11.5,10h2.719a1.5,1.5,0,0,1,0,3ZM1.616,6.83A1.5,1.5,0,0,1,.267,5.99,12.381,12.381,0,0,1-1,.5a1.5,1.5,0,0,1,3,0,9.406,9.406,0,0,0,.962,4.171A1.5,1.5,0,0,1,1.616,6.83Z" transform="translate(1 1)" fill="#241f21"/></svg>

          <button onClick={calculateReimbursements} className="calculate-button">Calculate</button>
        </div>
        {showResults && (
          <div className="results-section">
            <div className="result-box">
              {amount[1] === "Impossible"
                ? <h2>🚂 Train travel is not possible from this location</h2>
                : (
                  <div>
                    <h2>For 🚂 train travel, you will get reimbursed up to <span className="blue">€{amount[1]}</span> ({distance[1]}km)</h2>
                    <h3 className="footerDisc">Only normal trains, no high-speed trains.</h3>
                  </div>
                )}
            </div>
            <div className="result-box">
              {amount[0] === "Impossible"
                ? <h2>✈️ Flight travel is not possible from this location</h2>
                : <h2>For ✈️ flight travel, you will get reimbursed up to <span className="blue">€{amount[0]}</span> ({distance[0]}km)</h2>}
            </div>
          </div>
        )}
      </div>
      <div className="footerDisc">
        <p>
          This calculator is based on the European Commission’s ‘Calculate unit costs for eligible travel costs’:
          <a href="https://commission.europa.eu/funding-tenders/procedures-guidelines-tenders/information-contractors-and-beneficiaries/calculate-unit-costs-eligible-travel-costs_en">
            https://commission.europa.eu/funding-tenders/procedures-guidelines-tenders/information-contractors-and-beneficiaries/calculate-unit-costs-eligible-travel-costs_en
          </a>.
          Please verify the latest updates on this page for ensuring the accuracy of the calculation. The European Youth Forum is not responsible for any errors in calculations.
        </p>
        <p className="disclaimerData">Data © OpenStreetMap contributors, ODbL 1.0. http://osm.org/copyright</p>
      </div>
    </div>
  );
}

export default App;
