export function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

export function distanceBetweenTwoPoints(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);  // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

/*
400-600	245
601-800	261
801-1200	276
1201-1600	288
1601-2000	369
2001-2500	429
2501-3500	541
3501-4500	659
4501-6000	796
6001-7500	900
7501-10000	1.201
10001-Max	1.376
*/
export function refundAmount(distance, location) {
  console.log(location)
  console.log(location.country_code)
  if (distance < 400) {
    if (distance > 50) {
      if (location.country_code === "de") {
        return 82
      }
      else if (location.country_code === "fr") {
        return 82
      }
      else if (location.country_code === "nl") {
        return 82
      }
      else if (location.country_code === "lu") {
        return 50
      }
      else if (location.country_code === "gb"){
        return 82
      }
    }
    return 0;
  } else if (distance < 600) {
    return 245;
  } else if (distance < 800) {
    return 261;
  } else if (distance < 1200) {
    return 276;
  } else if (distance < 1600) {
    return 288;
  } else if (distance < 2000) {
    return 369;
  } else if (distance < 2500) {
    return 429;
  } else if (distance < 3500) {
    return 541;
  } else if (distance < 4500) {
    return 659;
  } else if (distance < 6000) {
    return 796;
  } else if (distance < 7500) {
    return 900;
  } else if (distance < 10000) {
    return 1201;
  } else {
    return 1376;
  }
}
/*
Aruba [Country]
1343
Bonaire [Municipality]
1344
Curaçao [Country]
1302
French Polynesia [State]
2204
Greenland [Country]
1118
Guadeloupe [State]
801
French Guiana [State]
905
Martinique [State]
958
Mayotte [State]
1170
New Caledonia [Archipelago]
2065
Réunion [State]
1040
Saba [Municipality]
1286
Saint Helena
2395
Saint Martin (France) [state]
939
Saint Pierre and Miquelon [state]
1832
Wallis and Futuna [state]
2398
*/
export function edgeCases(location) {
  if (location['ISO3166-2-lvl3'] === "NL-AW")
    return 1343
  if (location["ISO3166-2-lvl8"] === "BQ-BO")
    return 1344
  if (location["ISO3166-2-lvl3"] === "NL-CW")
    return 1302
  if (location["ISO3166-2-lvl3"] === "FR-PF")
    return 2204
  if (location.country_code === "gl")
    return 1118
  if (location["ISO3166-2-lvl3"] === "FR-971")
    return 801
  if (location["ISO3166-2-lvl3"] === "FR-973")
    return 905;
  if (location["ISO3166-2-lvl3"] === "FR-972")
    return 958;
  if (location["ISO3166-2-lvl3"] === "FR-976")
    return 1170;
  if (location["ISO3166-2-lvl3"] === "FR-NC")
    return 2065;
  if (location["ISO3166-2-lvl3"] === "FR-974")
    return 1040;
  if (location["ISO3166-2-lvl8"] === "BQ-SA")
    return 1286;
  if (location["ISO3166-2-lvl3"] === "SH-HL")
    return 2395;
  if (location["ISO3166-2-lvl3"] === "FR-MF")
    return 939;
  if (location["ISO3166-2-lvl3"] === "NL-SX")
    return 939;
  if (location["ISO3166-2-lvl3"] === "FR-PM")
    return 1832;
  if (location["ISO3166-2-lvl3"] === "FR-WF")
    return 2398;
  return false
}

export function checkIfEURouterIsCloseToDepart(location, departure) {
  return distanceBetweenTwoPoints(location.lat, location.lon, departure.lat, departure.lon) < 100
}

// ISO 3166-1 alpha-2 for countries in Europe
// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
/* 
be  Belgium
bg  Bulgaria
cy  Cyprus
at  Austria
cz  Czechia
de  Germany
dk  Denmark
ee  Estonia
es  Spain
fi  Finland
fr  France
hr  Croatia
hu  Hungary
gl  Greenland
gr  Greece
ie  Ireland
it  Italy
lt  Lithuania
lu  Luxembourg
lv  Latvia
mt  Malta
nl  Netherlands
pl  Poland
pt  Portugal
ro  Romania
se  Sweden
si  Slovenia
sk  Slovakia

al albania
am armenia
ad andorra
by belarus
ba bosnia & herzegovina
ge georgia
is iceland
xk kosovo
md moldova
mc monaco
me montenegro
mk north macedonia
no norway
sm san marino
rs serbia
ch swizterlanmd
tr turkey
ua ukraine
gb united kingdom
*/
